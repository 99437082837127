<template>
  <v-card style="margin-bottom:1em">
    <v-toolbar color="primary darken-4" dark>
      <v-toolbar-title>{{ label }}</v-toolbar-title>
    </v-toolbar>
    <v-list>
      <v-list-item v-for="({ name, mimeType, path, editName, selected, downloading }, fileIndex) in files" :key="'file-' + fileIndex" :disabled="downloading" @click="open(name, path, fileIndex)">
        <v-list-item-avatar v-if="multiselect">
          <v-icon v-if="path.substr(0, 7) !== 'http://' && path.substr(0, 8) !== 'https://'">{{ selected ? 'fas fa-check-square' : 'fal fa-square' }}</v-icon>
        </v-list-item-avatar>
        <v-list-item-avatar v-else>
          <v-icon v-if="path.substr(0, 7) === 'http://' || path.substr(0, 8) === 'https://'">fal fa-link</v-icon>
          <v-icon v-else-if="mimeType === 'application/pdf'">fal fa-file-pdf</v-icon>
          <v-icon v-else-if="mimeType === 'application/zip' || mimeType === 'application/x-zip-compressed'">fal fa-file-archive</v-icon>
          <v-icon v-else-if="mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'">fal fa-file-spreadsheet</v-icon>
        </v-list-item-avatar>
        <v-list-item-title style="white-space: normal;">{{ downloading ? 'Downloading file...' : name }}</v-list-item-title>
        <v-list-item-avatar v-if="multiselect">
          <v-icon v-if="path.substr(0, 7) === 'http://' || path.substr(0, 8) === 'https://'">fal fa-link</v-icon>
          <v-icon v-else-if="mimeType === 'application/pdf'">fal fa-file-pdf</v-icon>
          <v-icon v-else-if="mimeType === 'application/zip' || mimeType === 'application/x-zip-compressed'">fal fa-file-archive</v-icon>
          <v-icon v-else-if="mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'">fal fa-file-spreadsheet</v-icon>
        </v-list-item-avatar>
      </v-list-item>
    </v-list>
  </v-card>
</template>
<script>
import axios from 'axios'
export default {
  props: {
    label: {
      type: String,
      required: true
    },
    files: {
      type: Array,
      required: true
    },
    multiselect: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { root, emit }) {
    let baseApiUrl = process.env.VUE_APP_API_URL + process.env.VUE_APP_API_PATH
    baseApiUrl = baseApiUrl.substring(0, baseApiUrl.length - 10)
    function open (name, path, fileIndex) {
      if (props.multiselect) {
        emit('selectFile', fileIndex)
      } else if (path.substr(0, 4) === 'http') {
        window.open(path)
        if (root.$store.state.board.accessToken) {
          root.$feathers.service('board/users').patch(root.$store.state.board.accessToken, { lastAccess: new Date() })
        }
      } else {
        emit('downloading', fileIndex)
        let token = window.localStorage['feathers-jwt']
        axios({
          method: 'get',
          url: baseApiUrl + '/board/drive-file/' + path,
          responseType: 'blob',
          headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` }
        }).catch((e) => {
          let snackbar = { active: true, color: 'error', timeout: 6000, text: 'Error downloading file: ' + e }
          root.$store.dispatch('main/snackbar', snackbar)
        }).then((response) => {
          const blob = new Blob([response.data], { type: response.headers['content-type'] })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = name
          if (name.substr(-4, 1) !== '.' && name.substr(-5, 1) !== '.') {
            switch (response.headers['content-type']) {
              case 'application/pdf':
                link.download += '.pdf'
                break
              case 'txt':
                link.download += '.txt'
            }
          }
          link.click()
          emit('downloadFinished', fileIndex)
        })
      }
    }

    return {
      open
    }
  }
}
</script>
